import store from "../store";
import router from "../router";
import { STATUS_CAMPAIGN } from "@/assets/js/helper";

export function renderSeconds(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d / 60);
  var s = Math.floor(d);

  if (d / 3600 > 1) {
    return new Intl.NumberFormat(navigator.language).format(h) + " hours";
  } else if (d / 60 > 1) {
    return m + " min " + Math.round(d % 60);
  } else {
    return s + " sec";
  }
}

export function convertToSeconds(timeStr) {
  if (!timeStr) {
    return "";
  }

  const [hours, minutes, seconds] = timeStr.split(":").map(Number);
  return hours * 3600 + minutes * 60 + (seconds || 0);
}

export function getDisplayUnity(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d / 60);

  if (h > 2) {
    return "hours";
  } else if (m > 2) {
    return "minutes";
  } else {
    return "seconds";
  }
}

export function convertSecondesToUnity(d, unity) {
  d = Number(d);

  if (unity == "hours") {
    var h = Math.round((d / 3600) * 10) / 10;
    return h;
  } else if (unity == "minutes") {
    var m = Math.round((d / 60) * 10) / 10;
    return m;
  } else {
    return d;
  }
}

export function getTimestamp(date, toMidnight = false) {
  const d = new Date(date);

  if (!toMidnight) {
    return d.getTime() / 1000;
  }

  return Math.floor(d.setHours(23, 59, 59, 999) / 1000);
}

export function getDayTimestampUTC(date, endOfTheDay = false) {
  const d = new Date(date);

  if (!endOfTheDay) {
    return Math.floor(d.setUTCHours(0, 0, 0, 0) / 1000);
  }

  return Math.floor(d.setUTCHours(23, 59, 59, 999) / 1000);
}

export function getQueryFilters(filters) {
  const route = router.history.current;
  const globalFilters = store.state.stats.filters;

  if (globalFilters.programs.length > 0) {
    filters.programs = globalFilters["programs"];
  }
  if (globalFilters.episodes.length > 0) {
    filters.episodes = globalFilters["episodes"];
  }
  if (globalFilters.campaigns.length > 0) {
    filters.spids = globalFilters["campaigns"];
  }
  if (globalFilters.modes.length > 0) {
    filters.modes = globalFilters["modes"];
  }
  if (globalFilters.utm_campaign.length > 0) {
    filters.utm_campaign = globalFilters["utm_campaign"];
  }
  if (globalFilters.utm_medium.length > 0) {
    filters.utm_medium = globalFilters["utm_medium"];
  }
  if (globalFilters.utm_source.length > 0) {
    filters.utm_source = globalFilters["utm_source"];
  }
  if (globalFilters.utm_content.length > 0) {
    filters.utm_content = globalFilters["utm_content"];
  }
  if (globalFilters.devices.length > 0) {
    filters.devices = globalFilters["devices"];
  }
  if (globalFilters.browsers.length > 0) {
    filters.browsers = globalFilters["browsers"];
  }
  if (globalFilters.page_type.length > 0) {
    filters.page_type = globalFilters["page_type"];
  }
  if (globalFilters.domains.length > 0) {
    filters.domains = globalFilters["domains"];
  }
  if (typeof route.params.id !== "undefined") {
    filters.programs = route.params.id;
  }
  return filters;
}

export function getStatusCampaign(item) {
  let status = "unknown";

  const startDate = new Date(item.minDate);
  const endDate = new Date(item.maxDate);
  const statusCampaign = item.activated;
  const todayDate = new Date();

  if (todayDate > endDate) {
    status = "finished";
  } else if (statusCampaign && startDate > todayDate) {
    status = "pending";
  } else if (statusCampaign && startDate <= todayDate <= endDate) {
    status = "running";
  } else if (!statusCampaign && startDate <= todayDate <= endDate) {
    status = "paused";
  }

  item.status = {
    name: status,
    class: STATUS_CAMPAIGN[status]
  };

  return item;
}

export function isURL(url) {
  if (!url) {
    return false;
  }

  const regex = /^(http|https):\/\//;
  return regex.test(url);
}

export function isValidJsonString(str) {
  if (!/^(\{|\[)/.test(str.trim()) || !/(\}|\])$/.test(str.trim())) {
    return false;
  }

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isEmail(email) {
  const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regexEmail.test(email);
}

export function isJsonString(string) {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }

  return true;
}

export const isDifferenceLessThanXDays = (startDate, endDate, XDays) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    throw new Error("Invalid date.");
  }

  const diffTime = Math.abs(end - start);

  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays <= XDays;
};
