<template>
  <b-table
    stacked="sm"
    class="cutom-table"
    v-on:row-clicked="handleClick"
    v-if="items.length > 0"
    :fields="filteredFields"
    hover
    :items="items"
  >
    <template v-slot:cell(date)="row">
      {{ $d(new Date(row.item.date), "short") }}
    </template>

    <template v-slot:cell(name)="row">
      <span v-bind:class="rowClass(row.item)" style="cursor: pointer">
        {{ row.item.name }}
      </span>
    </template>

    <template #custom-foot>
      <b-tr>
        <b-td v-for="(column, index) in fields" :key="column.key">
          <b class="float-right" v-if="index === 0">Total</b>
          <b v-else>{{ calculateTotal[column.key] }}</b>
        </b-td>
      </b-tr>
    </template>
  </b-table>
</template>

<style>
.cutom-table thead th {
  color: #b5b5c3;
  text-transform: uppercase;
  font-weight: 600;
}

.cutom-table tr td:first-child {
  font-weight: 600;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_FILTERS } from "@/store/stats.module";

export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rowClickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filteredFields() {
      return this.fields.map(field => {
        if (field.role === "admin") {
          if (this.isAdmin()) {
            return field;
          } else {
            return false;
          }
        }
        return field;
      });
    },
    calculateTotal() {
      const except = [
        "conversionRate.total",
        "fillRate.total",
        "rpmImpression.total",
        "rpmPlay.total"
      ];

      const data = {};
      this.fields.forEach((field, index) => {
        if (index !== 0 && !except.includes(field.key)) {
          data[field.key] = this.items.reduce((total, item) => {
            const [level1, level2] = field.key.split(".");

            if (!level2) {
              return total + item[level1];
            }

            const value = item[level1][level2] || 0;

            return total + value;
          }, 0);

          data[field.key] = data[field.key];
        }
      });

      const conversionRate = this.conversionRate(
        data["paidPlay.total"],
        data["paidImpression.total"]
      );
      const fillRate = this.fillRate(
        data["paidPlay.total"],
        data["paidInventory.total"]
      );
      const rpmImpression = this.rpm(
        data["paidImpression.total"],
        data["earning.total"]
      );
      const rpmPlay = this.rpmPlays(
        data["organicPlay.total"],
        data["earning.total"]
      );

      data["paidInventory.total"] = new Intl.NumberFormat(
        navigator.language
      ).format(data["paidInventory.total"]);
      data["paidImpression.total"] = new Intl.NumberFormat(
        navigator.language
      ).format(data["paidImpression.total"]);
      data["paidPlay.total"] = new Intl.NumberFormat(navigator.language).format(
        data["paidPlay.total"]
      );
      data["organicPlay.total"] = new Intl.NumberFormat(
        navigator.language
      ).format(data["organicPlay.total"]);
      data["conversionRate.total"] = conversionRate + "%";
      data["fillRate.total"] = fillRate + "%";
      data["earning.total"] = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR"
      }).format(data["earning.total"]);
      data["rpmImpression.total"] = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR"
      }).format(rpmImpression);
      data["rpmPlay.total"] = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR"
      }).format(rpmPlay);

      return data;
    }
  },
  methods: {
    ...mapGetters(["isAdmin", "isPaidCustomer", "filters"]),
    isFloat(number) {
      return number % 1 !== 0;
    },
    conversionRate(plays, impressions) {
      const cr = impressions > 0 ? (plays / impressions) * 100 : 0;
      return cr.toFixed(2);
    },
    fillRate(plays, inventory) {
      const fr = inventory > 0 ? (plays / inventory) * 100 : 0;
      return fr.toFixed(2);
    },
    rpm(impressions, earnings) {
      const rpm = impressions > 0 ? (earnings / impressions) * 1000 : 0;
      return rpm.toFixed(2);
    },
    rpmPlays(plays, earnings) {
      const rpm = plays > 0 ? (earnings / plays) * 1000 : 0;
      return rpm.toFixed(2);
    },
    handleClick(item) {
      if (!this.rowClickable) return;

      let feedHash = "";

      if (item.hasOwnProperty(feedHash)) {
        feedHash = item.feedHash;
      } else if (item.hasOwnProperty("program")) {
        feedHash = item.program.id;
        if (item.program.hasOwnProperty("hash")) {
          feedHash = item.program.hash;
        }
      }

      this.$store.dispatch(SET_FILTERS, {
        networks: [],
        programs: [feedHash]
      });
    },
    rowClass(item) {
      if (!item) return;

      let feedHash = "";

      if (item.hasOwnProperty(feedHash)) {
        feedHash = item.feedHash;
      } else if (item.hasOwnProperty("program")) {
        feedHash = item.program.id;
      }

      if (!this.filters().programs || this.filters().programs.length == 0)
        return;
      if (this.filters().programs.includes(feedHash)) {
        return "text-primary";
      }
    }
  }
};
</script>
