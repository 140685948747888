<template>
  <div>
    <TabsCards v-bind:tabs="tabsData" v-on:change="selectTab"></TabsCards>

    <v-row class="row-cards">
      <ChartCard
        v-bind:sizes="{
          default: 12,
          sm: 12,
          lg: getLgColumns
        }"
        v-bind:chart="chart"
        v-bind:customOptions="customOptions"
        v-bind:visibleCard="true"
      >
      </ChartCard>

      <Heatmap
        v-if="isDisplayHeatmap"
        :loading="isLoadingHeatmap"
        :chartOptions="chartOptions"
        :series="series"
      />

      <Chart
        v-if="isDisplayDoughnutChart"
        id-graph="graph-program"
        key="graph-program"
        :title="$tc('AUDIENCE_PAGE.TITLE_DOGHNUT_PROGRAMS')"
        :optionsGraph="doughnutChartPrograms"
        :loading="isLoadingDoughnutChart"
        :dataLength="doughnutChartPrograms.series[0].data.length"
      />

      <Chart
        v-if="isDisplayDoughnutChart"
        id-graph="graph-device"
        key="graph-device"
        :title="$tc('AUDIENCE_PAGE.TITLE_CHART_DEVICE')"
        :optionsGraph="chartDevices"
        :loading="isLoadingDeviceChart"
        :dataLength="chartDevices.dataset.source.length"
      />

      <CreateCampaign
        v-if="isDipayCreateCampaign"
        style="min-height: 380px"
        v-bind:sizes="{
          default: 12,
          sm: 12,
          lg: 4
        }"
      />
    </v-row>

    <v-row class="row-cards" v-if="isDisplayRankingEpisodes">
      <RankingEpisodes :episodes="episodes" :loading="isLoadingEpisodes" />
    </v-row>

    <slot>
      <v-row v-if="displayChart" class="row-cards">
        <Table
          v-bind:loading="chart.loading"
          v-bind:items="table.data"
          v-bind:visibleCard="true"
        ></Table>
      </v-row>
    </slot>
  </div>
</template>

<script>
import TabsCards from "@/views/partials/content/cards/TabsCards";
import Table from "@/views/partials/content/cards/Table.vue";
import ChartCard from "@/views/partials/content/cards/Chart";
import ApiService from "@/common/api.service";
import { mapGetters } from "vuex";
import {
  getQueryFilters,
  renderSeconds,
  isDifferenceLessThanXDays
} from "@/common/functions";
import Heatmap from "@/views/partials/content/cards/Heatmap";
import CreateCampaign from "@/views/partials/content/cards/CreateCampaign";
import {
  chartDevices,
  doughnutChartPrograms
} from "@/components/layout/stats.config";
import Chart from "@/components/charts/Chart";
import RankingEpisodes from "@/components/RankingEpisode";
import {
  getFollowersGlobal,
  getFollowersDaily
} from "@/api/dashboard/followers";
import {
  getAudiencesGlobal,
  getAudiencesDaily,
  getQuartilesGlobal,
  getPublishersHeatmap,
  getProgramsGlobal,
  getDevicesGlobal,
  getEpisodesGlobal
} from "@/api/dashboard/audiences";
import { getRevenuesGlobal, getRevenuesDaily } from "@/api/dashboard/revenues";
import {
  getCampaignsDaily,
  getCampaignsGlobal
} from "@/api/dashboard/campaigns";

const defaultQuartileValues = {
  vtr_0: {
    "quartiles:total": 0,
    "quartiles:paid": 0,
    "quartiles:organic": 0
  },
  vtr_25: {
    "quartiles:total": 0,
    "quartiles:paid": 0,
    "quartiles:organic": 0
  },
  vtr_50: {
    "quartiles:total": 0,
    "quartiles:paid": 0,
    "quartiles:organic": 0
  },
  vtr_75: {
    "quartiles:total": 0,
    "quartiles:paid": 0,
    "quartiles:organic": 0
  },
  vtr_100: {
    "quartiles:total": 0,
    "quartiles:paid": 0,
    "quartiles:organic": 0
  }
};

export default {
  name: "statspage",
  props: {
    displayChart: {
      type: Boolean,
      default: true
    },
    displayHeatmap: {
      type: Boolean,
      default: false
    },
    tabsData: {
      type: Array
    },
    APIslug: {
      type: String
    },
    displayCreate: {
      type: Boolean
    },
    adminFilters: {
      type: Array,
      required: false,
      default: () => []
    },
    inputFilters: {
      type: Array,
      required: false,
      default: () => []
    },
    visibleCard: {
      type: Boolean,
      required: false
    },
    displayDoughnutChart: {
      type: Boolean,
      required: false
    },
    displayRankingEpisodes: {
      type: Boolean,
      required: false
    },
    maxQueryDays: {
      type: Number,
      required: false,
      default: 92
    }
  },
  components: {
    Chart,
    ChartCard,
    Table,
    TabsCards,
    Heatmap,
    CreateCampaign,
    RankingEpisodes
  },
  data() {
    return {
      timeout: 40000,

      table: {
        data: []
      },
      export2: {
        data: []
      },
      chartCountsCancelToken: null,
      DetailsCancelToken: null,
      APIdata: {},
      customOptions: {},
      isLoadingHeatmap: false,
      chart: {
        title: "Listens",
        legends: "",
        type: "bar",
        data: {
          labels: [],
          datasets: []
        },
        loading: true
      },
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "heatmap"
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#8c8c8c"]
          }
        },
        colors: ["#FF961D"],
        xaxis: {
          type: "Days",
          categories: [],
          crosshairs: {
            show: true
          }
        }
      },
      doughnutChartPrograms,
      chartDevices,
      isLoadingDoughnutChart: false,
      isLoadingDeviceChart: false,
      isLoadingEpisodes: false,
      episodes: []
    };
  },
  mounted() {
    if (this.$route.query.tab) {
      this.selectTab(this.$route.query.tab);
      this.$router.replace({ query: null });
    }

    this.showChartCounts();
    this.fetchAudience();

    if (this.isDisplayHeatmap) {
      this.loadHeatmap();
    }

    if (this.isDisplayDoughnutChart) {
      this.loadDoughnutCharts();
      this.loadDevicesChart();
    }

    if (this.isDisplayRankingEpisodes) {
      this.loadRankingEpisode();
    }
  },
  beforeDestroy() {
    doughnutChartPrograms.series[0].data = [];
  },
  computed: {
    ...mapGetters(["filters", "header", "isPremium"]),

    isDisplayHeatmap() {
      return this.isPremium && this.displayHeatmap;
    },

    isDipayCreateCampaign() {
      return !this.isPremium && this.displayCreate;
    },

    isDisplayDoughnutChart() {
      return this.displayDoughnutChart;
    },

    isDisplayRankingEpisodes() {
      return this.displayRankingEpisodes;
    },

    getLgColumns() {
      let nbrColumns = 12;

      if (this.isDisplayHeatmap) {
        nbrColumns = 6;
      } else if (this.isDipayCreateCampaign) {
        nbrColumns = 8;
      }

      return nbrColumns;
    }
  },
  watch: {
    filters() {
      try {
        this.periodeChecker();
      } catch (error) {
        this.tableIsLoading = false;
        this.$bvToast.toast(`Data recovery`, {
          title: `${error}`,
          toaster: "b-toaster-bottom-right",
          solid: true,
          variant: "danger",
          appendToast: false,
          autoHideDelay: 5000
        });

        return;
      }

      this.showChartCounts();
      this.fetchAudience();

      if (this.isDisplayHeatmap) {
        this.loadHeatmap();
      }

      if (this.isDisplayDoughnutChart) {
        this.loadDoughnutCharts();
        this.loadDevicesChart();
      }

      if (this.isDisplayRankingEpisodes) {
        this.loadRankingEpisode();
      }
    }
  },

  methods: {
    ...mapGetters(["isAdmin", "isPaidCustomer"]),
    showDatas(datas, graph) {
      if (graph.renderFigure) {
        if (graph.value) {
          graph.primaryStat = graph.renderFigure(datas[graph.value]);
        } else {
          graph.primaryStat = graph.renderFigure(datas);
        }
      } else {
        // TODO uniformiser avec le module i18n de nuxt
        graph.primaryStat = new Intl.NumberFormat(navigator.language).format(
          datas[graph.value]
        );
      }
      graph.loading = false;
    },

    showChartCounts() {
      if (this.filters.dateRange && this.filters.dateRange.length > 1) {
        for (const key in this.tabsData) {
          this.showDatas([], this.tabsData[key]);
          this.tabsData[key].loading = true;
        }

        new Promise(() => {
          if (this.chartCountsCancelToken != null) {
            this.chartCountsCancelToken.cancel(
              ApiService.getCancelErrorMessage()
            );
          }

          this.chartCountsCancelToken = ApiService.getCancelToken();

          let statsFunction;
          switch (this.APIslug) {
            case "audience":
              statsFunction = getAudiencesGlobal;
              break;
            case "follower":
              statsFunction = getFollowersGlobal;
              break;
            case "revenue":
              statsFunction = getRevenuesGlobal;
              break;
            case "sponsorings":
              statsFunction = getCampaignsGlobal;
              break;
            default:
              break;
          }

          statsFunction(
            this.axios,
            {},
            { ...this.getQueryFilters() },
            {
              cancelToken: this.chartCountsCancelToken.token,
              timeout: this.timeout
            }
          )
            .then(data => {
              for (const key in this.tabsData) {
                this.showDatas(data, this.tabsData[key]);
                this.tabsData[key].loading = false;
              }
              if (data.podcasts) {
                this.$emit("podcasts", data.podcasts);
              }
            })
            .catch(error => {
              if (error.message !== "Operation canceled due to new request.") {
                for (const key in this.tabsData) {
                  this.tabsData[key].loading = false;
                }
              }
            });
        });
      }
    },

    getQueryFilters() {
      let filters = {
        start_date: this.filters.dateRange[0].substring(0, 10),
        end_date:
          new Date(this.filters.dateRange[1]) > new Date()
            ? new Date().getTime() / 1000
            : this.filters.dateRange[1].substring(0, 10)
      };

      let header = this.adminFilters.concat(this.inputFilters);

      return getQueryFilters(filters, header);
    },

    getFilterStr(filterSlug) {
      return this.filters[filterSlug] ? this.filters[filterSlug].join(",") : "";
    },

    selectTab(key) {
      for (const i in this.tabsData) {
        if (key !== i) {
          this.tabsData[i].selected = false;
        }
      }
      this.tabsData[key].selected = true;
      if (this.tabsData[key].dataSrc) {
        this.fetchQuartile();
      } else {
        this.normalizeChartData(this.APIdata);
      }
    },

    getSelectedTab() {
      return this.tabsData.findIndex(tab => {
        return tab.selected === true;
      });
    },

    normalizeLabels(labels) {
      return labels.map(label => {
        return this.formalizedDateLabel(label);
      });
    },

    normalizeDuration(duration) {
      return renderSeconds(duration);
    },

    formalizedDateLabel(label) {
      return this.$d(new Date(label), "short");
    },

    normalizeAudienceDataTable(periodDatas) {
      delete periodDatas["total"];
      delete periodDatas["debugQuery"];
      let results = [];

      for (var key in periodDatas) {
        results.push({
          date: this.formalizedDateLabel(key),
          listens:
            new Intl.NumberFormat(navigator.language).format(
              periodDatas[key]["listens:total"]
            ) || 0,
          // page_views:
          //   new Intl.NumberFormat(navigator.language).format(
          //     periodDatas[key]["page:organic"] - periodDatas[key]["page:paid"]
          //   ) || 0,
          organic_listens:
            new Intl.NumberFormat(navigator.language).format(
              periodDatas[key]["listens:organic"]
            ) || 0,
          paid_listens:
            new Intl.NumberFormat(navigator.language).format(
              periodDatas[key]["listens:paid"]
            ) || 0,
          avg_listening_time: this.normalizeDuration(
            periodDatas[key]["atimelistenings:total"] || 0
          ),
          total_listening_time: this.normalizeDuration(
            periodDatas[key]["ttimelistenings:total"] || 0
          )
        });
      }
      this.table.data = results;
    },

    normalizeChartData(data) {
      let selectedTab = this.tabsData[this.getSelectedTab()];

      delete data["total"];
      const labels = this.normalizeLabels(Object.keys(data));
      const values = Object.values(data);

      let datasets = [];
      for (let chartDataToDisplay2 of selectedTab.chartDataToDisplay) {
        const figures = values.map(dayData => {
          if (chartDataToDisplay2.renderData) {
            return chartDataToDisplay2.renderData(dayData) || 0;
          }
          return dayData[chartDataToDisplay2.value] || 0;
        });

        const dataset = {
          data: figures,
          type: chartDataToDisplay2?.type,
          label: chartDataToDisplay2.label,
          backgroundColor: chartDataToDisplay2.color,
          borderColor: chartDataToDisplay2.borderColor
            ? chartDataToDisplay2.borderColor
            : chartDataToDisplay2.color
        };

        datasets.push(dataset);
      }

      this.chart.title = selectedTab.chartTitle
        ? selectedTab.chartTitle
        : selectedTab.title;
      this.customOptions = selectedTab.customOptions;
      this.chart = {
        ...this.chart,
        ...{
          data: { datasets, labels },
          type: selectedTab.chartOptions?.chartType
            ? selectedTab.chartOptions?.chartType
            : "bar",
          stacked: selectedTab.chartOptions?.stacked,
          isTimeChart: selectedTab.isTimeChart
        }
      };
    },

    normalizeChartData2(data) {
      delete data["debugQuery"];
      let selectedTab = this.tabsData[this.getSelectedTab()];
      let datasets = [];
      // Sort value
      var orderedKeys = ["vtr_0", "vtr_25", "vtr_50", "vtr_75", "vtr_100"];
      const values = orderedKeys.map(key => {
        return data[key];
      });

      let labels = orderedKeys.map(label => {
        return label.substring(4, 10) + " %";
      });

      for (let chartDataToDisplay2 of selectedTab.chartDataToDisplay) {
        let figures = values.map(dayData => {
          if (chartDataToDisplay2.renderData) {
            return chartDataToDisplay2.renderData(dayData);
          }
          return dayData[chartDataToDisplay2.value];
        });

        const max = Math.max(...figures);

        // Display as %
        figures = figures.map(figure => {
          return {
            y: Math.round((figure / max) * 100),
            value: figure,
            max: max
          };
        });

        datasets.push({
          data: figures,
          label: chartDataToDisplay2.label,
          backgroundColor: chartDataToDisplay2.color,
          borderColor: chartDataToDisplay2.borderColor
            ? chartDataToDisplay2.borderColor
            : chartDataToDisplay2.color
        });
      }

      this.chart.title = selectedTab.title;

      this.customOptions = selectedTab.customOptions;
      this.chart = {
        ...this.chart,
        ...{
          data: { datasets: datasets, labels: labels },
          isTimeChart: selectedTab.isTimeChart,
          type: selectedTab.chartOptions?.chartType
            ? selectedTab.chartOptions?.chartType
            : "bar",
          stacked: false
        }
      };
    },

    fetchAudience() {
      if (this.filters.dateRange && this.filters.dateRange.length > 1) {
        this.chart.loading = true;
        this.APIdata = [];

        let selectedTab = this.tabsData[this.getSelectedTab()];

        if (selectedTab.dataSrc) {
          this.fetchQuartile();
        } else {
          new Promise(() => {
            if (this.detailsCancelToken != null) {
              this.detailsCancelToken.cancel(
                ApiService.getCancelErrorMessage()
              );
            }

            this.detailsCancelToken = ApiService.getCancelToken();

            let statsFunction;
            switch (this.APIslug) {
              case "audience":
                statsFunction = getAudiencesDaily;
                break;
              case "follower":
                statsFunction = getFollowersDaily;
                break;
              case "revenue":
                statsFunction = getRevenuesDaily;
                break;
              case "sponsorings":
                statsFunction = getCampaignsDaily;
                break;
              default:
                break;
            }

            if (statsFunction === undefined) {
              return;
            }

            statsFunction(
              this.axios,
              {},
              { ...this.getQueryFilters() },
              {
                cancelToken: this.detailsCancelToken.token,
                timeout: this.timeout
              }
            )
              .then(data => {
                this.APIdata = data;

                // Thats ugly
                let filteredData = Object.keys(data).reduce((obj, key) => {
                  obj[key] = { ...data[key] };
                  return obj;
                }, {});

                this.normalizeChartData(filteredData);

                if (selectedTab.normalizeDataTable) {
                  this.table.data = selectedTab.normalizeDataTable(data);
                } else {
                  this.normalizeAudienceDataTable(filteredData);
                }
                if (typeof selectedTab.normalizeExportData == "function") {
                  this.export2.data = selectedTab.normalizeExportData(data);
                } else {
                  this.export2.data = this.table.data;
                }

                this.$root.$emit("exportData", this.export2.data);

                this.chart.loading = false;
              })
              .catch(error => {
                if (
                  error.message !== "Operation canceled due to new request."
                ) {
                  this.chart.loading = false;
                }
              });
          });
        }
      }
    },

    async loadHeatmap() {
      this.isLoadingHeatmap = true;

      this.chartOptions.xaxis.categories = [];

      try {
        if (this.heatmapCancelToken != null) {
          this.heatmapCancelToken.cancel(ApiService.getCancelErrorMessage());
        }

        this.heatmapCancelToken = ApiService.getCancelToken();

        const response = await getPublishersHeatmap(
          this.axios,
          {},
          { ...this.getQueryFilters() },
          {
            cancelToken: this.heatmapCancelToken.token,
            timeout: this.timeout
          }
        );
        const weeksData = Object.keys(response);

        let heatmapCategories = [];
        let series = [];
        weeksData.reverse().forEach((element, index) => {
          const data = response[element][0];

          if (index === 0) {
            heatmapCategories = Object.keys(data);
          }

          let prepareSerires = {
            name: element,
            data: []
          };

          Object.keys(data).forEach(isDisplayHeatmap => {
            prepareSerires.data.push(data[isDisplayHeatmap]);
          });

          series.push(prepareSerires);
        });

        this.chartOptions.xaxis.categories = heatmapCategories;
        this.series = series;
      } catch (error) {
        console.error("---ERROR-HEATMAP---");
        console.error(error);
      }

      this.isLoadingHeatmap = false;
    },

    fetchQuartile() {
      if (this.filters.dateRange && this.filters.dateRange.length > 1) {
        this.chart.loading = true;
        this.normalizeChartData2(defaultQuartileValues);

        new Promise(() => {
          if (this.quartilesCancelToken != null) {
            this.quartilesCancelToken.cancel(
              ApiService.getCancelErrorMessage()
            );
          }

          this.quartilesCancelToken = ApiService.getCancelToken();

          getQuartilesGlobal(
            this.axios,
            {},
            { ...this.getQueryFilters() },
            {
              cancelToken: this.quartilesCancelToken.token,
              timeout: this.timeout
            }
          )
            .then(data => {
              this.normalizeChartData2(data);
              this.chart.loading = false;
            })
            .catch(error => {
              if (
                error.message !==
                "[KT] ApiService Cancel: Operation canceled due to new request."
              ) {
                this.chart.loading = false;
              }
            });
        });
      }
    },

    async loadDoughnutCharts() {
      this.isLoadingDoughnutChart = true;

      doughnutChartPrograms.series[0].data = [];

      try {
        if (this.slitProgramsCancelToken != null) {
          this.slitProgramsCancelToken.cancel(
            ApiService.getCancelErrorMessage()
          );
        }

        this.slitProgramsCancelToken = ApiService.getCancelToken();

        const response = await getProgramsGlobal(
          this.axios,
          {},
          { ...this.getQueryFilters() },
          {
            cancelToken: this.slitProgramsCancelToken.token,
            timeout: this.timeout
          }
        );

        let listNumber = [];

        let dataGraph = [];

        response.forEach(element => {
          listNumber.push(element["listen:total"]);
          dataGraph.push({
            value: element["listen:total"],
            name: element.programName
          });
        });

        doughnutChartPrograms.series[0].data = dataGraph;
      } catch (error) {
        console.error("---ERROR-DOUGHNUT-CHARRTS---");
        console.error(error);
      }

      this.isLoadingDoughnutChart = false;
    },

    async loadDevicesChart() {
      this.isLoadingDeviceChart = true;
      chartDevices.dataset.source = [];

      try {
        if (this.slitDevicesCancelToken != null) {
          this.slitDevicesCancelToken.cancel(
            ApiService.getCancelErrorMessage()
          );
        }

        this.slitDevicesCancelToken = ApiService.getCancelToken();

        const response = await getDevicesGlobal(
          this.axios,
          {},
          { ...this.getQueryFilters() },
          {
            cancelToken: this.slitDevicesCancelToken.token,
            timeout: this.timeout
          }
        );

        const hearderSource = ["plays:rate", "device", "plays:total"];
        let dataGraph = [hearderSource];
        response.forEach(element => {
          dataGraph.push([
            element["plays:rate"],
            element["device"],
            element["plays:total"]
          ]);
        });
        chartDevices.dataset.source = dataGraph;
      } catch (error) {
        console.error("---ERROR-DEVICES-CHARRTS---");
        console.error(error);
      }

      this.isLoadingDeviceChart = false;
    },

    async loadRankingEpisode() {
      this.isLoadingEpisodes = true;
      this.episodes = [];

      try {
        if (this.rankingEpisodesCancelToken != null) {
          this.rankingEpisodesCancelToken.cancel(
            ApiService.getCancelErrorMessage()
          );
        }

        this.rankingEpisodesCancelToken = ApiService.getCancelToken();

        const response = await getEpisodesGlobal(
          this.axios,
          {},
          { ...this.getQueryFilters() },
          {
            cancelToken: this.rankingEpisodesCancelToken.token,
            timeout: this.timeout
          }
        );

        this.episodes = response;
      } catch (error) {
        console.error("---ERROR-RANKING_EPISODES---");
        console.error(error);
      }

      this.isLoadingEpisodes = false;
    },

    periodeChecker() {
      const filters = this.getQueryFilters();

      if (
        !isDifferenceLessThanXDays(
          filters.start_date,
          filters.end_date,
          this.maxQueryDays
        )
      ) {
        throw new Error(
          `The period should not exceed ${this.maxQueryDays} days`
        );
      }
      return true;
    }
  }
};
</script>

<style scoped>
.isDisplayHeatmap {
  height: 396px !important;
}
</style>
